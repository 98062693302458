
import { defineComponent, ref, nextTick } from 'vue'
import { PlusCircleOutlined } from '@ant-design/icons-vue'
import clickOutside from '@/views/Planning/directive/clickOutside'
import { getScenrioList, saveScenrio, removeScenrio, updateScenrioActive } from "@/API/planning";
import { message ,Modal} from "ant-design-vue";
export default defineComponent({
  components: {
    PlusCircleOutlined
  },
  directives: {
    clickOutside
  },
  setup() {
    const columns = [{
      title: "Planning Scenario",
      width: 100,
      dataIndex: "scenario",
      slots: { customRender: 'scenario' }
    },
    {
      title: "Status",
      width: 150,
      align: 'center',
      dataIndex: "status",
      slots: { customRender: 'status' }
    },
    {
      title: "Operation",
      width: 50,
      align: 'center',
      slots: { customRender: 'operation' }
    }]
    const tableData = ref<any>([])
    const initData = async () => {
      const _params = {
        activeStatus: false
      }
      const _res = await getScenrioList(_params)
      const _data = _res.data || []
      _data.forEach((item: any) => {
        item.activeStatus = item.activeStatus ? 'Active' : 'Inactive'
      })
      tableData.value = _data
    }
    initData()
    const handleEdittingStatus = (record: any) => {
      record.edittingStatus = true
    }

    const handleEdittingName = (record: any) => {
      record.edittingName = true
    }

    const handleCancelEdittingStatus = (record: any) => {
      record.edittingStatus = false
      record.edittingName = false
    }

    const handleAddNew = () => {
      const _idList = tableData.value.map((item: any) => {
        return item.id
      })
      const _max = Math.max(..._idList)
      tableData.value.push({
        id: _max + 1,
        name: '',
        activeStatus: 'Active',
        edittingStatus: false,
        edittingName: false,
        editting: true
      })
      nextTick(() => {
        const ele: any | null = document.querySelector(`.ant-table-wrapper`)
        ele.scrollTop = ele.scrollHeight + 40
      })
    }

    const handleActiveStatusChange = (record: any) => {
      const postData = {
        scenarioId: record.id,
        activeStatus: record.activeStatus === 'Active'
      }
      updateScenrioActive(postData).then(() => {
        record.edittingStatus = false
      })
    }

    const handleDelete = (record: any, index: number) => {
      Modal.confirm({
        content: `${record.name} will be deleted`,
        onOk() {
          const text = `${record.name} has transaction data, cannot be deleted.`
          removeScenrio(record.id).then((res) => {
            if (res) {
              tableData.value.splice(index, 1)
            } else {
              message.error(text)
            }
          })
        }
      })
    }

    const handleSave = async () => {
      const _postData = tableData.value.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          activeStatus: item.activeStatus === 'Active' ? true : false
        }
      })
      // 校验空值
      const isNullFlag = _postData.some((item: any) => {
        return !item.name
      })
      // 校验重复名字
      const names = _postData.map((item: any) => {
        return item.name
      })
      const namesSet = new Set(names)
      const isRepeatFlag: boolean = namesSet.size !== names.length
      if (isNullFlag) {
        return message.error('Please input scenario name.')
      } 
      if (isRepeatFlag) {
        return message.error('Scenario name cannot be repeated.')
      }
      await saveScenrio(_postData)
      message.success('Save Success')
      await initData()
      tableData.value.forEach((item: any) => {
        item.editting = false
      })
      const ele: any | null = document.querySelector(`.ant-table-wrapper`)
      ele.scrollTop = 0
    }
    
    return {
      columns,
      tableData,
      handleEdittingStatus,
      handleEdittingName,
      handleCancelEdittingStatus,
      handleAddNew,
      handleActiveStatusChange,
      handleDelete,
      handleSave
    }
  }
})
